import { asInternalEvent } from '../utils/helpers';

//Emitted from parent
export const fromParent = {
  APPLY: asInternalEvent('apply', 'parent'),
  GET: asInternalEvent('get', 'parent'),
};

//Emitted from child
export const fromChild = {
  APPLY: asInternalEvent('apply', 'child'),
  SET: asInternalEvent('set', 'child'),
  GET: asInternalEvent('get', 'child'),
  EVENT: asInternalEvent('event', 'child'),
};
