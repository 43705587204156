import { STORAGE_ENV_KEY_NAME, ENVS, CONF_COOKIE_NAME } from '../consts';
import getConfFromUrl from './getConfFromUrl';
import getEnvFromUrl from './getEnvFromUrl';
export { default as replaceConfigEnvTemplate } from './replaceConfigEnvTemplate';

export function getAppEnv() {
  try {
    // eslint-disable-next-line no-undef
    return APP_ENV;
  } catch (e) {
    return undefined;
  }
}

export function getAppConfig() {
  try {
    // eslint-disable-next-line no-undef
    return APP_CONFIG;
  } catch (e) {
    return undefined;
  }
}

export function getNodeEnv() {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV;
}

export function getRawConfig() {
  try {
    // eslint-disable-next-line no-undef
    return ENV || {};
  } catch (e) {
    return {};
  }
}

export function getLocalStorageEnv() {
  try {
    return localStorage.getItem(STORAGE_ENV_KEY_NAME);
  } catch (e) {
    console.warn('env-config', 'Cannot read environment override from local storage');
  }
}

export function get(object, path, defaultVal) {
  const _path = Array.isArray(path) ? path : path.split('.').filter((i) => i.length);

  if (!_path.length) {
    return object;
  } else if (object === undefined) {
    return defaultVal;
  }

  return get(object[_path.shift()], _path, defaultVal);
}

export function getEnvTypeForNumberedEnvs(envName = '') {
  const INTERNAL_ENVS = [ENVS.STAGING, ENVS.NIGHTLY];

  return INTERNAL_ENVS.find((env) => envName.match(`-${env}\\d*`) || envName.match(`^${env}\\d*`));
}

export function getUrlEnv() {
  const envFromUrl = getEnvFromUrl();
  // return envFromUrl when:
  // - is equals to one of the env types
  // - it's a numbered env e.g. staging7
  // - it includes "eod4cld" or  "console" prefix
  if (getEnvTypeForNumberedEnvs(envFromUrl) || Object.values(ENVS).find((env) => env === envFromUrl) || /^(eod4cld|console)/.test(envFromUrl)) {
    return envFromUrl;
  }
}

export function getUrlConf() {
  const confFromUrl = getConfFromUrl();
  // if the confFromUrl is either equals to one of the env types or it's a numbered env e.g. staging7
  return getEnvTypeForNumberedEnvs(confFromUrl) || Object.values(ENVS).find((env) => env === confFromUrl);
}

export function getCookie(cName) {
  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie); //to be careful

  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) {
      res = val.substring(name.length);
    }
  });
  return res;
}

function getConfCookieNameSuffix() {
  const currentScript = document.currentScript;
  if (!currentScript) {
    console.warn('This code must run synchronously, make sure you import it first on the entry point of your app');
    return;
  }
  const scriptUrl = new URL(currentScript.src);

  if (document.location.hostname === scriptUrl.hostname) {
    return;
  }
  const regex = /(.*?)(\.cloudinary\.com)?$/;

  return (regex.exec(scriptUrl.hostname) || [])[1];
}

/*
for widgets, there is a script that's running on the customer's website
so we cannot set a cookie on the script's domain (script cannot read it)
in case the script origin is different from the browser origin, we are reading the cookie from the customer's website domain
and in order to differentiate between apps, we are giving it a suffix with the application name (subdomain)
*/
export const getConfCookieName = (appName = getConfCookieNameSuffix()) => [CONF_COOKIE_NAME, appName].filter(Boolean).join('-');
