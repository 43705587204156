export const EXPORT = 'export';
export const PREV = 'prev';
export const NEXT = 'next';
export const HEADER_CLICK = 'headerclick';
export const CROP_CLICK = 'cropclick';
export const INTERACTIVE_CROP_RESIZE = 'interactivecropresize';
export const INTERACTIVE_CROP_MOVE = 'interactivecropmove';
export const DIMENSIONS_EDIT = 'dimensionsedit';
export const OVERLAY_CLICK = 'overlayclick';
export const OVERLAY_PLACEMENT_CLICK = 'overlayplacementclick';
export const ASPECT_RATIO_CLICK = 'aspectratioclick';
export const CLOSE = 'close';
export const DONE = 'done';
export const COPY_URL = 'copyurl';
export const DOWNLOAD = 'download';
export const FORMAT_CLICK = 'formatclick';
export const QUALITY_CLICK = 'qualityclick';
export const ALL_ANALYTICS = 'analytics';
export const SIGN = 'sign';
export const FLIPVERTICALLY = 'flipvertically';
export const FLIPHORIZONTALLY = 'fliphorizontally';
export const ROTATECLOCKWISE = 'rotateclockwise';
export const ROTATECOUNTERCLOCKWISE = 'rotatecounterclockwise';
export const TEXTPRESET_CLICK = 'textpresetclick';
export const FONTSELECT = 'fontselect';
export const FONTSIZE = 'fontsize';
export const FONTSTYLE = 'fontstyle';
export const FONTCOLOR = 'fontcolor';
export const TEXTALIGN = 'textalign';
export const TEXTOVERLAYDELETE = 'textoverlaydelete';

export const TRIM_START_MOVED = 'trimstartmoved';
export const TRIM_END_MOVED = 'trimendmoved';
export const TRIM_CLIP_MOVED = 'trimclipmoved';

export const PLAY_PAUSE_CLICKED = 'playpauseclicked';
export const SOUND_BUTTON_CLICKED = 'soundbuttonclicked';
export const VOLUME_MOVED = 'volumemoved';
export const SEEK_MOVED = 'seekmoved';
