import * as incomingEvents from 'constants/incomingEvents';
import * as outgoingEvents from 'constants/outgoingEvents';
import {
  createIframe,
  parentBuilder,
  withConfiguration,
  withOmitInternalEvents,
  withParentProxy,
  withVisibility,
} from '@cld/widget-iframe-creator-parent';
import { getIframeUrlFromScriptUrl } from '@cld/widget-script-url';
import withVersion from 'iframe/withVersion';
import getEnvConfig from '@cld/env-config';
import { parent as withAssetSigning } from 'iframe/withAssetSigning';
import { parent as withTriggerExport } from 'iframe/withTriggerExport';
import { iframeId } from './constants';

const iframeUrl = getEnvConfig().iframeUrl || getIframeUrlFromScriptUrl();

const mediaEditor = createIframe({
  src: iframeUrl,
  iframeAttr: { id: iframeId },
  incomingEvents,
  outgoingEvents,
  Parent: parentBuilder(withVisibility, withParentProxy, withConfiguration, withOmitInternalEvents, withVersion, withAssetSigning, withTriggerExport),
});

window.cloudinary || (window.cloudinary = {});

window.cloudinary.mediaEditor = mediaEditor;

export default mediaEditor;
