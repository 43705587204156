import { asInternalEvent } from '../utils/helpers';

//Emitted from parent
export const fromParent = {
  UPDATE_CONFIG: asInternalEvent('updateConfig', 'parent'),
  CONFIG_UPDATE_RESULTS: asInternalEvent('configUpdateResults', 'parent'),
};

//Emitted from child
export const fromChild = {
  UPDATE_CONFIG: asInternalEvent('updateConfig', 'child'),
};
