const stringifyEvent = (e) => {
  const obj = {};
  for (let k in e) {
    obj[k] = e[k];
  }
  return JSON.stringify(obj, (k, v) => {
    if (v instanceof Node || v instanceof Window) {
      return v.toString();
    }
    return v;
  });
};

export const iframeMessageCreator = (type, data) => {
  let eventData = data;
  if (isEvent(data)) {
    eventData = stringifyEvent(data);
  } else {
    try {
      eventData = JSON.stringify(data);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  return {
    type,
    data: eventData,
  };
};

export const iframeMessageReceiver = ({ type, data }) => {
  let eventData = data;
  if (typeof data === 'string') {
    try {
      eventData = JSON.parse(data);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  return {
    type,
    data: eventData,
  };
};

export const asArray = (objOrArray) => (Array.isArray(objOrArray) ? objOrArray : Object.values(objOrArray));

const isEvent = (obj) => obj instanceof Event;

export const createEnhancerSuperInstance = ({ fn, args: { incomingEvents = [], outgoingEvents = [], ...args } = {}, incoming = [], outgoing = [] }) =>
  fn({
    incomingEvents: incomingEvents.concat(asArray(incoming)),
    outgoingEvents: outgoingEvents.concat(asArray(outgoing)),
    ...args,
  });

export const createBuilder =
  (coreFn) =>
  (...enhancers) => {
    let result = coreFn;

    for (const enhancer of enhancers) {
      result = enhancer(result);
    }

    return ({ incomingEvents = {}, outgoingEvents = {}, ...args } = {}) =>
      result({ incomingEvents: asArray(incomingEvents), outgoingEvents: asArray(outgoingEvents), ...args });
  };

const toSnakeCase = (str) => {
  if (!str) {
    return '';
  }

  return String(str)
    .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
    .replace(/([a-z])([A-Z])/g, (m, a, b) => a + '_' + b.toLowerCase())
    .replace(/[^A-Za-z0-9]+|_+/g, '_');
};

const INTERNAL_EVENTS_PREFIX = '@INTERNAL';

export const asInternalEvent = (eventName, side = '') => `${INTERNAL_EVENTS_PREFIX}.${side.toUpperCase()}.${toSnakeCase(eventName)}`.toUpperCase();

export const filterInternalEvents = (eventsList) => eventsList.filter((eventName) => !eventName.startsWith(INTERNAL_EVENTS_PREFIX));

export const getEventName = (eventString) => {
  const eventName = eventString.startsWith(INTERNAL_EVENTS_PREFIX) ? eventString.split('.')[2] : eventString;

  return eventName.toUpperCase();
};
