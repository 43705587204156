import { asInternalEvent } from '../utils/helpers';

//Emitted from parent
export const fromParent = {
  SHOW: asInternalEvent('show', 'parent'),
  HIDE: asInternalEvent('hide', 'parent'),
};

//Emitted from child
export const fromChild = {
  SHOW: 'show',
  HIDE: 'hide',
};
