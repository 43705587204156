const getElement = (selectorOrElement) => (typeof selectorOrElement === 'string' ? document.querySelector(selectorOrElement) : selectorOrElement);

export const createIframe = (src, selectorOrElement, { style, ...iframeAttr } = {}) => {
  const iframe = document.createElement('iframe');

  Object.assign(iframe, iframeAttr, { src });
  Object.assign(iframe.style, style); // we need to merge the style and not replace it

  const target = getElement(selectorOrElement);
  target.appendChild(iframe);

  return iframe;
};
