import * as outgoingEvents from 'constants/outgoingEvents';
import * as incomingEvents from 'constants/incomingEvents';
import { createEnhancerSuperInstance } from '@cld/widget-iframe-creator-core';

export const parent = (fn) => (args) => {
  const parent = createEnhancerSuperInstance({ fn, args });

  let currentCb;
  //We are merging the signing result with the asset details so we can associate the response to the relevant asset in the app
  parent.on(incomingEvents.SIGN, async ({ id, ...args }) =>
    parent.emit(outgoingEvents.SIGN, Object.assign({ id }, currentCb ? await currentCb(args) : {}))
  );

  return {
    ...parent,
    onSign: (newCb) => {
      currentCb = newCb;
    },
  };
};
