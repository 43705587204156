import { createEnhancerSuperInstance } from '@cld/widget-iframe-creator-core';
import * as outgoingEvents from '../constants/outgoingEvents';

export const parent = (fn) => (args) => {
  const parent = createEnhancerSuperInstance({ fn, args });

  return {
    ...parent,
    triggerExport: () => {
      parent.emit(outgoingEvents.TRIGGER_EXPORT);
    },
  };
};
