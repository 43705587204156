import { createEnhancerSuperInstance, filterInternalEvents } from '@cld/widget-iframe-creator-core';
export default (fn) => (args) => {
  const parent = createEnhancerSuperInstance({ fn, args });

  return {
    ...parent,

    getEvents: () => {
      const events = parent.getEvents();

      return {
        incoming: filterInternalEvents(events.incoming),
        outgoing: filterInternalEvents(events.outgoing),
      };
    },
  };
};
