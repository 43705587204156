import { iframeMessageCreator, iframeMessageReceiver } from './helpers';

export default (function () {
  let eventsMapping = {};

  return ({ element = window, incomingEvents = [], outgoingEvents = [], origin } = {}) => {
    const ele = element.contentWindow || element.parent; //contentWindow => iframe, parent => window

    const receiveMsgHandler = (event) => {
      if (!event?.data) {
        return;
      }
      //TODO: if Child is not in the expected origin, ignore
      const { type, data } = iframeMessageReceiver(event.data);

      if (eventsMapping[type]) {
        eventsMapping[type].forEach((cb) => cb(data));
      }
    };

    window.addEventListener('message', receiveMsgHandler, false);

    const on = (eventName, cb) => {
      if (~incomingEvents.indexOf(eventName)) {
        eventsMapping[eventName] || (eventsMapping[eventName] = []);
        const idx = eventsMapping[eventName].push(cb) - 1;

        return () => {
          delete eventsMapping[eventName][idx];
        };
      }
    };

    const emit = (type, data) => {
      if (~outgoingEvents.indexOf(type)) {
        ele.postMessage(iframeMessageCreator(type, data), origin);
      }
    };

    const close = () => {
      eventsMapping = {};
      window.removeEventListener('message', receiveMsgHandler);
    };

    return {
      emit,
      on,
      close,
    };
  };
})();
