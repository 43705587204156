import { withConfigurationEventTypes, createEnhancerSuperInstance } from '@cld/widget-iframe-creator-core';

//TODO: do we want to get this function from outside?
const handleConfigErrors = (errors) => {
  console.error(errors.join('\n'));
};

export default (fn) => (args) => {
  const { fromChild, fromParent } = withConfigurationEventTypes;

  const parent = createEnhancerSuperInstance({ fn, args, incoming: fromChild, outgoing: fromParent });

  let config;
  let errors;

  const handleUpdateConfig = (configUpdateResult) => {
    config = configUpdateResult && configUpdateResult.config;
    errors = configUpdateResult && configUpdateResult.errors;

    parent.emit(fromParent.CONFIG_UPDATE_RESULTS, configUpdateResult);

    if (errors && errors.length) {
      handleConfigErrors(errors);
    }
  };

  parent.on(fromChild.UPDATE_CONFIG, handleUpdateConfig);

  return {
    ...parent,

    replace: (config) => {
      parent.emit(fromParent.UPDATE_CONFIG, { config, replace: true });
    },
    update: (configDelta) => {
      parent.emit(fromParent.UPDATE_CONFIG, { config: configDelta, replace: false });
    },

    getConfig: () => (config ? { ...config } : config),
    getErrors: () => (errors ? [...errors] : errors),
  };
};
