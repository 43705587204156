import { createEnhancerSuperInstance } from '@cld/widget-iframe-creator-core';

export default (fn) => (args) => {
  const parent = createEnhancerSuperInstance({ fn, args });

  return {
    ...parent,
    getVersion: () => VERSION, //eslint-disable-line no-undef
  };
};
