import { withParentProxyEventTypes, createEnhancerSuperInstance } from '@cld/widget-iframe-creator-core';

const withQuerySelector =
  (func) =>
  ({ selector, ...params }) =>
    func({ element: selector ? document.querySelector(selector) : window, ...params });

const get = (obj, path) =>
  !path
    ? obj
    : path.split('.').reduce((obj, section, idx, sectionsList) => {
        if (!obj) {
          return undefined;
        }

        if (idx === sectionsList.length - 1 && typeof obj[section] === 'function') {
          return obj[section].bind(obj);
        }

        return obj[section];
      }, obj);

const generateId = () => '_' + Math.random().toString(36).substr(2, 9);

export default (fn) => (args) => {
  const { fromParent, fromChild } = withParentProxyEventTypes;

  const parent = createEnhancerSuperInstance({ fn, args, outgoing: fromParent, incoming: fromChild });

  const removeEventFns = {};

  parent.on(
    fromChild.APPLY,
    withQuerySelector(({ path, args = [], element }) => {
      parent.emit(fromParent.APPLY, get(element, path)(...args));
    })
  );

  parent.on(
    fromChild.GET,
    withQuerySelector(({ path, element }) => {
      parent.emit(fromParent.GET, get(element, path));
    })
  );

  parent.on(
    fromChild.SET,
    withQuerySelector(({ path, value, element }) => {
      const [pathToLastKey, lastKey] = /^(.*?)\.?([^.]*)$/.exec(path).splice(1);
      get(element, pathToLastKey)[lastKey] = value;
    })
  );

  parent.on(
    fromChild.EVENT,
    withQuerySelector(({ originalEvent, internalEvent, element }) => {
      const eventCb = (e) => parent.emit(internalEvent, e);
      element.addEventListener(originalEvent, eventCb);
      const removeCbId = generateId(); //TODO: add option to cancel the event listener
      removeEventFns[removeCbId] = () => element.removeEventListener(originalEvent, eventCb);
    })
  );

  return {
    ...parent,

    destroy: () => {
      Object.values(removeEventFns).forEach((fn) => fn());

      return parent.destroy();
    },
  };
};
