import { withVisibilityEventTypes, createEnhancerSuperInstance } from '@cld/widget-iframe-creator-core';

export default (fn) => (args) => {
  const { fromChild, fromParent } = withVisibilityEventTypes;

  const parent = createEnhancerSuperInstance({ fn, args, incoming: fromChild, outgoing: fromParent });

  const show = async () => {
    const iframe = await parent.getIframe();
    iframe.style.display = '';
    parent.emit(fromParent.SHOW);
  };

  const hide = async () => {
    const iframe = await parent.getIframe();
    parent.emit(fromParent.HIDE);
    iframe.style.display = 'none';
  };

  parent.on(fromChild.SHOW, show);
  parent.on(fromChild.HIDE, hide);

  return {
    ...parent,

    show,
    hide,
  };
};
