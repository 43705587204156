import { createEmitter, coreEventTypes } from '@cld/widget-iframe-creator-core';
import { createIframe } from './utils';

const MAX_LOAD_TIME_IN_SEC = 15;

const defaultIframeAttr = {
  style: {
    display: 'none',
    border: 'none',
    width: '100%',
    height: '100%',
  },
};

const defaultInitConfig = {
  appendTo: 'body',
};

export default ({ src, initConfig = {}, iframeAttr = {}, incomingEvents = [], outgoingEvents = [] } = {}) => {
  let emitter;
  let iframe;

  const { fromChild } = coreEventTypes;
  const { appendTo } = { ...defaultInitConfig, ...initConfig };
  const iframeNotLoadedError = startIframeLoadingTimer();

  incomingEvents.push(fromChild.LOADED);

  let asyncIframe = new Promise((resolve) => {
    iframe = createIframe(src, appendTo, { ...defaultIframeAttr, ...iframeAttr });
    emitter = createEmitter({ element: iframe, incomingEvents, outgoingEvents, origin: src });

    emitter.on(fromChild.LOADED, () => {
      resolve(iframe);
      clearTimeout(iframeNotLoadedError);
    });
  });

  return {
    destroy: () => {
      emitter.close();
      emitter = null;
      iframe.parentElement.removeChild(iframe);
      asyncIframe = null;
    },

    emit: (type, data) => {
      asyncIframe.then(() => emitter.emit(type, data));
    },

    on: (eventName, cb) => emitter.on(eventName, cb),

    getEvents: () => ({ incoming: [...incomingEvents], outgoing: [...outgoingEvents] }),

    getIframe: () => asyncIframe,
  };
};

function startIframeLoadingTimer() {
  return setTimeout(
    () =>
      console.error(
        `iframe was not loaded after ${MAX_LOAD_TIME_IN_SEC} sec, maybe you forgot to load the middleware or call the "loaded" function on the child app?`
      ),
    MAX_LOAD_TIME_IN_SEC * 1000
  );
}
